<template>
	<v-card outlined :color="`blue lighten-5`" class="h-100 d-flex flex-column">
		<v-card-title>
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2" color="cyan">mdi-format-list-text</v-icon>
			<div class="my-auto fw-600 text-capitalize fs-18">Seo Project Recurring</div>
			<v-spacer></v-spacer>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn height="35" min-height="35" color="cyan lighten-4" depressed v-bind="attrs" v-on="on">
						<span class="fs-16 text-capitalize px-3">{{ filter }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in filterStatusList"
						:key="index"
						@click="filterData(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': filter == item,
							},
						]"
					>
						<v-list-item-title>{{ item }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>
		<v-card-text class="flex-grow-1">
			<div class="v-data-table bt-table theme--light">
				<div class="v-data-table__wrapper" style="height: 400px">
					<table id="table" width="100">
						<thead>
							<tr>
								<th class="py-2">Company</th>
								<th width="150">Performance</th>
								<th>Keyword</th>
								<th width="100">Status</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(data, index) in lists" :key="index">
								<td>
									<div class="d-flex align-items-center">
										<div>
											<v-img
												width="60"
												height="40"
												:src="data.logo ? data.logo : $assetURL('media/custom-svg/companylogo.svg')"
												contain
											/>
										</div>
										<div class="px-2">
											<p class="fw-500 fs-15">{{ data.company }}</p>
											<div class="d-flex align-center">
												<a :href="data.urls" target="_blank" v-if="data.urls" class="fw-500 mb-0 red--text">{{
													data.urls
												}}</a>
												<em class="text-muted" v-else>no web url</em>
											</div>
										</div>
									</div>
								</td>
								<td>
									<div class="" style="min-width: 200px">
										<div class="d-flex">
											<span class="fw-600 mr-2"> Top 5 : </span>
											<template v-if="data.ranking_data || data.to_customer">
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<div v-on="on" v-bind="attrs" class="d-flex justify-center align-center mr-3">
															<span
																v-if="data.ranking_data"
																:class="[
																	data.to_customer
																		? kpiColorIdentifier(
																				data.to_customer.kpi_6_month_top_5,
																				data.to_customer.top_5,
																				data.ranking_data?.top_5,
																				data.month_passed
																		  )
																		: '',
																]"
																>{{
																	data.ranking_data && data.ranking_data?.top_5 > 0 ? data.ranking_data?.top_5 : "-"
																}}</span
															>
															<span>{{ data.ranking_data || data.to_customer ? "/" : "" }}</span>
															<span class="green--text">
																{{
																	data.to_customer
																		? data.month_passed > 6
																			? data.to_customer.top_5 > 0
																				? data.to_customer.top_5
																				: "-"
																			: data.to_customer.kpi_6_month_top_5 > 0
																			? data.to_customer.kpi_6_month_top_5
																			: "-"
																		: "-"
																}}
															</span>
														</div>
													</template>
													<span>To Customer</span>
												</v-tooltip>
											</template>
											<template v-if="data.to_delhi || data.ranking_data">
												<v-tooltip top="">
													<template #activator="{ on, attrs }">
														<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
															<span
																v-if="data.ranking_data"
																:class="[
																	data.to_customer
																		? kpiColorIdentifier(
																				data.to_customer.kpi_6_month_top_5,
																				data.to_customer.top_5,
																				data.ranking_data.top_5,
																				data.month_passed
																		  )
																		: '',
																]"
															>
																{{
																	hasKey(data, "ranking_data.top_5") &&
																	getValue(data, "ranking_data.top_5") &&
																	data.ranking_data.top_5 > 0
																		? data.ranking_data.top_5
																		: "-"
																}}
															</span>
															<span>{{ data.ranking_data || data.to_delhi ? "/" : "" }}</span>
															<span class="green--text">
																{{
																	data.to_delhi
																		? data.month_passed > 6
																			? data.to_delhi.top_5 > 0
																				? data.to_delhi.top_5
																				: "-"
																			: data.to_delhi.kpi_6_month_top_5 > 0
																			? data.to_delhi.kpi_6_month_top_5
																			: "-"
																		: "-"
																}}
															</span>
														</v-chip>
													</template>
													<span>To Delhi</span>
												</v-tooltip>
											</template>
										</div>
										<div class="d-flex">
											<span class="fw-600 mr-2"> Top 10 : </span>
											<template v-if="data.ranking_data || data.to_customer">
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<div v-on="on" v-bind="attrs" class="d-flex justify-center align-center mr-3">
															<span
																v-if="data.ranking_data"
																:class="[
																	data.to_customer
																		? kpiColorIdentifier(
																				data.to_customer.kpi_6_month_top_10,
																				data.to_customer.top_10,
																				data.ranking_data?.top_10,
																				data.month_passed
																		  )
																		: '',
																]"
																>{{
																	data.ranking_data && data.ranking_data?.top_10 > 0
																		? data.ranking_data?.top_10
																		: "-"
																}}</span
															>
															<span>{{ data.ranking_data || data.to_customer ? "/" : "" }}</span>
															<span class="green--text">
																{{
																	data.to_customer
																		? data.month_passed > 6
																			? data.to_customer.top_10 > 0
																				? data.to_customer.top_10
																				: "-"
																			: data.to_customer.kpi_6_month_top_10 > 0
																			? data.to_customer.kpi_6_month_top_10
																			: "-"
																		: "-"
																}}
															</span>
														</div>
													</template>
													<span>To Customer</span>
												</v-tooltip>
											</template>
											<template v-if="data.to_delhi || data.ranking_data">
												<v-tooltip top="">
													<template #activator="{ on, attrs }">
														<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
															<span
																v-if="data.ranking_data"
																:class="[
																	data.to_customer
																		? kpiColorIdentifier(
																				data.to_customer.kpi_6_month_top_10,
																				data.to_customer.top_10,
																				data.ranking_data.top_10,
																				data.month_passed
																		  )
																		: '',
																]"
															>
																{{
																	hasKey(data, "ranking_data.top_10") &&
																	getValue(data, "ranking_data.top_10") &&
																	data.ranking_data.top_10 > 0
																		? data.ranking_data.top_10
																		: "-"
																}}
															</span>
															<span>{{ data.ranking_data || data.to_delhi ? "/" : "" }}</span>
															<span class="green--text">
																{{
																	data.to_delhi
																		? data.month_passed > 6
																			? data.to_delhi.top_10 > 0
																				? data.to_delhi.top_10
																				: "-"
																			: data.to_delhi.kpi_6_month_top_10 > 0
																			? data.to_delhi.kpi_6_month_top_10
																			: "-"
																		: "-"
																}}
															</span>
														</v-chip>
													</template>
													<span>To Delhi</span>
												</v-tooltip>
											</template>
										</div>
										<div class="d-flex">
											<span class="fw-600 mr-2"> Top 100 : </span>
											<template v-if="data.ranking_data || data.to_customer">
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<div v-on="on" v-bind="attrs" class="d-flex justify-center align-center mr-3">
															<span
																v-if="data.ranking_data"
																:class="[
																	data.to_customer
																		? kpiColorIdentifier(
																				data.to_customer.kpi_6_month_top_100,
																				data.to_customer.top_100,
																				data.ranking_data?.top_100,
																				data.month_passed
																		  )
																		: '',
																]"
																>{{
																	data.ranking_data && data.ranking_data?.top_100 > 0
																		? data.ranking_data?.top_100
																		: "-"
																}}</span
															>
															<span>{{ data.ranking_data || data.to_customer ? "/" : "" }}</span>
															<span class="green--text">
																{{
																	data.to_customer
																		? data.month_passed > 6
																			? data.to_customer.top_100 > 0
																				? data.to_customer.top_100
																				: "-"
																			: data.to_customer.kpi_6_month_top_100 > 0
																			? data.to_customer.kpi_6_month_top_100
																			: "-"
																		: "-"
																}}
															</span>
														</div>
													</template>
													<span>To Customer</span>
												</v-tooltip>
											</template>
											<template v-if="data.to_delhi || data.ranking_data">
												<v-tooltip top="">
													<template #activator="{ on, attrs }">
														<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
															<span
																v-if="data.ranking_data"
																:class="[
																	data.to_customer
																		? kpiColorIdentifier(
																				data.to_customer.kpi_6_month_top_100,
																				data.to_customer.top_100,
																				data.ranking_data.top_100,
																				data.month_passed
																		  )
																		: '',
																]"
															>
																{{
																	hasKey(data, "ranking_data.top_100") &&
																	getValue(data, "ranking_data.top_100") &&
																	data.ranking_data.top_100 > 0
																		? data.ranking_data.top_100
																		: "-"
																}}
															</span>
															<span>{{ data.ranking_data || data.to_delhi ? "/" : "" }}</span>
															<span class="green--text">
																{{
																	data.to_delhi
																		? data.month_passed > 6
																			? data.to_delhi.top_100 > 0
																				? data.to_delhi.top_100
																				: "-"
																			: data.to_delhi.kpi_6_month_top_100 > 0
																			? data.to_delhi.kpi_6_month_top_100
																			: "-"
																		: "-"
																}}
															</span>
														</v-chip>
													</template>
													<span>To Delhi</span>
												</v-tooltip>
											</template>
										</div>
										<div class="d-flex">
											<span class="fw-600 mr-2">
												Not in SERP : {{ data.to_customer.keyword_SERP ? data.to_customer.keyword_SERP : 0 }}
											</span>
										</div>
									</div>
								</td>
								<td>
									<div class="d-flex">
										<div
											class="brown darken-1 rounded-4 white--text d-flex justify-center align-center"
											style="border-radius: 245px; height: 22px; width: 22px"
										>
											{{ data.total_keyword ? data.total_keyword : 0 }}
										</div>
										<div class="ml-3 font-level-1-bold" style="font-weight: 600">Total</div>
									</div>
									<div class="d-flex mt-1">
										<div
											class="rounded-4 white--text d-flex justify-center align-center font-level-1-bold"
											:class="[idnfColor(data)]"
											style="border-radius: 245px; height: 22px; width: 33px"
										>
											6 M
										</div>
									</div>
									<div class="d-flex mt-1">
										<div
											class="rounded-4 white--text font-level-1-bold d-flex justify-center align-center"
											style="border-radius: 245px; height: 22px; width: 33px"
											:class="[idnfColor(data)]"
											v-if="data.month_passed > 6"
										>
											12 M
										</div>
									</div>
								</td>
								<td>
									<v-chip class="mb-1 mr-1" label :color="statusColor(data.status_text)">
										{{ data.status_text }}
									</v-chip>
								</td>
							</tr>
						</tbody>
					</table>
					<div v-if="lists.length > 10" class="text-center py-5">
						<v-btn height="35" min-height="35" color="cyan" depressed>
							<span class="fs-16 text-capitalize px-3">View All</span>
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
					</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import dashboardMixin from "@/core/mixins/dashboard.listing.mixin.js";
export default {
	name: "RecurringProjectList",
	mixins: [dashboardMixin],
	data() {
		return {
			filter: "This Month",
			countLoading: false,
			filterStatusList: [
				"This Week",
				"Next Week",
				"This Month",
				"Next Month",
				"This Quarter",
				"Next Quarter",
			],
			lists: [
				{
					id: 340,
					company: "Business Thrust Pvt Ltd",
					logo: this.$assetURL("media/logos/bthrust.png"),
					urls: "www.bthrust.com",
					cost: 0,
					status: 1,
					status_text: "Active",
					target_google: "Google Singapore",
					ranking_data: null,
					month_passed: 0,
					to_customer: {
						id: 4,
						type: 1,
						top_3: 5,
						top_5: 15,
						seo_id: 340,
						top_10: 65465,
						top_20: 52541,
						top_50: 116,
						top_100: 894,
						project_id: 302,
						kpi_6_month_top_3: 128,
						kpi_6_month_top_5: 1053,
						kpi_6_month_top_10: 8535,
						kpi_6_month_top_20: 65,
						kpi_6_month_top_50: 45,
						kpi_6_month_top_100: 25635,
					},
					to_delhi: {
						id: 5,
						type: 2,
						top_3: 25,
						top_5: 0,
						seo_id: 340,
						top_10: 0,
						top_20: 0,
						top_50: 0,
						top_100: 0,
						project_id: 302,
						kpi_6_month_top_3: 10,
						kpi_6_month_top_5: 0,
						kpi_6_month_top_10: 0,
						kpi_6_month_top_20: 0,
						kpi_6_month_top_50: 0,
						kpi_6_month_top_100: 0,
					},
					team_lead: {
						id: 45,
						role: 8,
						profile_img: null,
						display_name: "team lead(seo)",
					},
				},
				{
					id: 340,
					company: "Business Thrust Pvt Ltd",
					logo: this.$assetURL("media/logos/bthrust.png"),
					urls: "www.bthrust.com",
					cost: 0,
					status: 2,
					status_text: "Hold",
					target_google: "Google Singapore",
					ranking_data: null,
					month_passed: 0,
					to_customer: {
						id: 4,
						type: 1,
						top_3: 5,
						top_5: 15,
						seo_id: 340,
						top_10: 65465,
						top_20: 52541,
						top_50: 116,
						top_100: 894,
						project_id: 302,
						kpi_6_month_top_3: 128,
						kpi_6_month_top_5: 1053,
						kpi_6_month_top_10: 8535,
						kpi_6_month_top_20: 65,
						kpi_6_month_top_50: 45,
						kpi_6_month_top_100: 25635,
					},
					to_delhi: {
						id: 5,
						type: 2,
						top_3: 25,
						top_5: 0,
						seo_id: 340,
						top_10: 0,
						top_20: 0,
						top_50: 0,
						top_100: 0,
						project_id: 302,
						kpi_6_month_top_3: 10,
						kpi_6_month_top_5: 0,
						kpi_6_month_top_10: 0,
						kpi_6_month_top_20: 0,
						kpi_6_month_top_50: 0,
						kpi_6_month_top_100: 0,
					},
					team_lead: {
						id: 45,
						role: 8,
						profile_img: null,
						display_name: "team lead(seo)",
					},
				},
			],
		};
	},
	methods: {
		filterData(item) {
			if (this.filter != item) {
				this.filter = item;
				this.countLoading = true;
				setTimeout(() => {
					this.countLoading = false;
				}, 2000);
			}
		},
	},
	computed: {},
};
</script>
<style lang="scss" scoped>
.v-data-table__wrapper {
	tr:nth-child(even) {
		background: #fbf5eb;
	}
	tr {
		td {
			padding: 12px 12px !important;
		}
	}
}
</style>
